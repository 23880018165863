import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { useRef, useState, } from 'react';
import { DropdownMenu } from '@shared/ui/menus/DropdownMenu';
import { ReactComponent as DropdownIcon } from '@icons/wolfkit-light/dots-3-vertical-light.svg';
import ButtonComponent from '@shared/ui/buttons/Button';
import getDropdownStyles from './styles';
const DropdownContainer = styled.div(() => ({
    position: 'relative',
}));
const Button = styled(ButtonComponent, { shouldForwardProp: propName => propName !== 'isActive' })(props => {
    var _a, _b, _c;
    return ({
        boxShadow: props.isActive ? (_c = (_b = (_a = getDropdownStyles(props.theme)) === null || _a === void 0 ? void 0 : _a[props.variant]) === null || _b === void 0 ? void 0 : _b[props.color]) === null || _c === void 0 ? void 0 : _c.boxShadowActive : 'unset',
        '.MuiButton-startIcon': {
            margin: 0,
        },
    });
});
const Dropdown = ({ options, buttonSize = 'medium', buttonVariant = 'plain', buttonColor = 'primary', role = 'list', placement = 'bottom-start', onClose = undefined, disabled = false, zIndex = undefined, menuListStyles = undefined, closeMenuAfterClick = undefined, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const anchorRef = useRef(null);
    const handleToggle = () => {
        setIsOpen((prevOpen) => !prevOpen);
    };
    const handleMenuClose = () => {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    };
    return (_jsxs(DropdownContainer, { children: [_jsx(Button, { ref: anchorRef, onClick: handleToggle, startIcon: DropdownIcon, id: 'composition-button', "aria-controls": isOpen ? 'composition-menu' : undefined, "aria-expanded": isOpen ? 'true' : undefined, "aria-haspopup": 'true', isActive: isOpen, disabled: disabled, size: buttonSize, variant: buttonVariant, color: buttonColor }), _jsx(DropdownMenu, { isOpen: isOpen, ref: anchorRef, placement: placement, role: role, items: options, zIndex: zIndex, onClose: handleMenuClose, listStyles: menuListStyles, keepMounted: true, transition: true, disablePortal: true, closeMenuAfterClick: closeMenuAfterClick })] }));
};
export default Dropdown;
